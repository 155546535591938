import { GetServerSideProps } from 'next';

import { PageRendererComponent, prePageCall } from '@marriott/mi-headless-utils';
import { mockModel } from '../mocks/mockModel';
import landingpageSignatures from '../signatures/landingpageSignatures.json';

import { NEXT_PUBLIC_ENV_KEYS } from '@marriott/mi-landingpage-components';

function pageRenderer(props) {
  console.log('[page] props ', props);
  return <PageRendererComponent {...props} />;
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const landingpageSpecificData = async () => {
  return {
    operationSignatures: landingpageSignatures,
    nextPublicEnvKeys: NEXT_PUBLIC_ENV_KEYS,
  };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps: GetServerSideProps<any> = prePageCall(landingpageSpecificData, mockModel);
export default pageRenderer;
